<template>
  <v-row class="my-4">
    <v-col cols="12">
      <v-card class="pa-2" flat>
        <v-row>
          <v-col>
            {{ getTeamName }}
          </v-col>
          <v-col>
            <div class="top float-right">
              <v-tooltip v-if="canManageTeam" bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-btn icon @click="leaveModal = true">
                      <v-icon>exit_to_app</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span> Leave Team </span>
              </v-tooltip>
              <v-tooltip v-if="canManageTeam" bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-btn icon>
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span> Delete Team </span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="3">
          <v-list>
            <template v-for="(option, index) in optionList">
              <v-list-item
                v-if="option.show"
                :key="index"
                :class="selectedOption === option.value ? 'primary ' : ''"
                @click="setAction(option.value)"
              >
                <div
                  :class="selectedOption === option.value ? 'white--text ' : ''"
                >
                  {{ option.title }}
                </div>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
        <v-col cols="12" md="9">
          <v-card class="pa-2" flat>
            <update-team-info v-if="selectedOption === 1"></update-team-info>
          </v-card>
          <transfer-ownership
            v-if="selectedOption === 2 && !showVerificationForm"
            @success="openVerificationForm"
          >
          </transfer-ownership>
          <verification-code-checker
            v-if="
              selectedOption === 2 &&
              showVerificationForm &&
              !showSuccessMessage
            "
            :memberDetail="selectedMember"
            action="transfer_ownership"
            from-verify-ownership
            @next="afterVerify"
          ></verification-code-checker>
          <v-card
            v-if="selectedOption === 2 && showSuccessMessage"
            class="pa-3"
          >
            <v-alert color="primary">
              You have successfully transfer you ownership. Now you can leave a
              team.
            </v-alert>
          </v-card>
          <v-card v-if="selectedOption === 3" class="pa-3" flat>
            <v-row>
              <v-col cols="12">
                <div class="subListFont">Delete my account after</div>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="deleteTime"
                  :items="deleteOptions"
                  filled
                  item-text="text"
                  item-value="value"
                  label="Select duration"
                ></v-select>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :outlined="$vuetify.theme.dark"
                class="text-transform-none"
                color="primary"
                large
              >
                Update
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <Modal v-model="leaveModal" no-py>
      <template #card>
        <leave-team-modal
          v-if="leaveModal"
          @close="leaveModal = false"
        ></leave-team-modal>
      </template>
    </Modal>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import UpdateTeamInfo from "@/view/components/Team/UpdateTeamInfo";
import TransferOwnership from "@/view/components/Team/Members/TransferOwnership";
import VerificationCodeChecker from "@/view/components/Common/VerificationCodeChecker";
import LeaveTeamModal from "@/view/components/Team/LeaveTeamModal";

export default {
  components: {
    LeaveTeamModal,
    VerificationCodeChecker,
    TransferOwnership,
    UpdateTeamInfo,
  },
  data() {
    return {
      selectedOption: 1,
      leaveModal: false,
      showVerificationForm: false,
      selectedMember: {},
      showSuccessMessage: false,
      deleteOptions: [
        {
          text: "1 month",
          value: "1",
        },
        {
          text: "3 months",
          value: "3",
        },
        {
          text: "6 months",
          value: "6",
        },
        {
          text: "9 months",
          value: "9",
        },
        {
          text: "1 year",
          value: "12",
        },
      ],
      deleteTime: "",
    };
  },
  computed: {
    ...mapGetters({
      getTeamName: "getTeamName",
      getTeamId: "getTeamId",
    }),
    optionList() {
      return [
        {
          title: "Edit Info",
          show: this.canManageTeam,
          value: 1,
        },
        {
          title: "Transfer Ownership",
          show: this.canManageTeam,
          value: 2,
        },
        {
          title: "Delete",
          show: this.canManageTeam,
          value: 3,
        },
      ];
    },
  },
  methods: {
    setAction(value) {
      this.selectedOption = value;
    },
    afterVerify() {
      this.showSuccessMessage = true;
      this.showVerificationForm = false;
    },
    openVerificationForm(detail) {
      this.selectedMember = detail;
      this.showVerificationForm = true;
    },
  },
};
</script>